@primaryColor: #088f42;
.tag1 {
  // width: '100%';
  // text-align: center;
  flex-wrap: wrap;
}

.anttag {
  height: 30px;
  font-size: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customSpaceAdd {
  display: flex;
  margin-bottom: 8;
  width: 100% !important;
}

.minusIcon {
  color: @primaryColor;
  font-size: 20px;
  vertical-align: middle;

  padding-top: 10px;
}

.tag1 .ant-space-item:first-child {
  width: auto !important;
}

.ant-input-number-handler {
  display: none !important;
}

.clearSignatureBtn {
  float: right;
  border: none;
  text-decoration: underline;
  color: @primaryColor;
}

.perksSmallRef {
  font-size: 11px;
}

@layout-sider-background: white;@layout-header-background: white;@tooltip-color: white;@primary-color: 088f42;