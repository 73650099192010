/* .ant-space-item:first-child {
  width: 100%;
} */

.ant-input-number-handler-up,
.ant-input-number-handler-down {
  display: none !important;
}

.ant-input-number-group-addon {
  background: none !important;
  border-color: #088f42 !important;
}

.ant-input-number-status-error {
  border-color: #088f42 !important;
}

.ant-input-group-addon {
  border-color: #088f42 !important;
  color: #000000 !important;
  background-color: #ffffff !important;
}
