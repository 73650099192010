.Header {
  height: auto !important;
  border-bottom: 1px solid darken(@layout-header-background, 10%);
  align-items: center;
  border: none;
  img {
    max-width: 80%;
    vertical-align: middle;
    padding: 5px;
  }

  .Divider {
    background: #d9d9d9;
    margin-left: 10px;
    align-self: center;
    height: 40px;
  }

  // @media (min-width: 426px) {
  //   .First {
  //     float: left;
  //     width: 15%;
  //     display: flex;
  //   }
  // }

  @media (max-width: 425px) {
    .First {
      float: left !important;
      // width: 0%;
      display: flex !important;
    }
  }

  @media (min-width: 768px) {
    .First {
      float: left !important;
      width: 23%;
      display: flex !important;
    }
  }
  .Second {
    // float: left;
    // width: 85%;
  }
}

@layout-sider-background: white;@layout-header-background: white;@tooltip-color: white;@primary-color: 088f42;