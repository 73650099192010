.ant-form-item-explain-error {
  white-space: break-spaces !important;
}

#senderForm_Password_help .ant-form-item-explain-error {
  white-space: break-spaces !important;

  margin-top: 10px;
}

#senderForm_Password_help .ant-form-item-explain-error .ant-alert-error {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
}

#senderForm_NewPassword_help .ant-form-item-explain-error {
  white-space: break-spaces !important;

  margin-top: 10px;
}

#senderForm_NewPassword_help .ant-form-item-explain-error .ant-alert-error {
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.pHeader {
  margin-top: 5px;
  margin-bottom: 0px;
  color: #545454;
}

.pBottom {
  margin-bottom: 3px;
  margin-top: 0px;
  color: #545454;
}
