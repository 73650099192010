.anttagcheckable {
  font-size: 15px;
  color: black;
  padding: 10px;
  justify-content: normal;
}
/* Sender.module.less */

.rightText {
  text-align: right;
  margin-right: 8px !important;
}

.link {
  margin-right: 8px !important;
}

@layout-sider-background: white;@layout-header-background: white;@tooltip-color: white;@primary-color: 088f42;