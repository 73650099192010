.pointerCursor {
  cursor: pointer;
}

.placeholderColor {
  color: #2121214d !important;
}
.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.curveButtonBorders {
  border-radius: 68px !important;
}

.dflex {
  display: flex !important;
}

.dInlineBlock {
  display: inline-block !important;
}

.vAlignSub {
  vertical-align: sub !important;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.shadowBorder {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
}

.fontBlue500 {
  color: #1890ff !important;
}

.fontBlue700 {
  color: #0050b3 !important;
}

.red {
  color: #ff4d4f !important;
}

.prewrap {
  white-space: pre-wrap;
}

.displayBlock {
  display: block;
}

.pageHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  padding-bottom: 20px;
  display: block;
}

.Header {
  display: flex;
  justify-content: space-between;
}

.width20 {
  width: 20%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width50 {
  width: 50%;
}

.width80 {
  width: 80%;
}

.width100 {
  width: 100%;
}

.maxwidth100 {
  max-width: 100% !important;
}

/* Spacing Classes*/
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-auto {
  margin-right: auto;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-auto {
  margin-left: auto;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-6 {
  padding-bottom: 5.5px !important;
}

.p-10 {
  padding: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/*Container Classes*/

/***************/

/*Drawer Menu Overrides*/

.ant-drawer-body {
  padding: 0 !important;
}

.ErrorMessage {
  text-align: center;
  color: #a80505 !important;
}

//Pagination Padding
.ant-table-pagination.ant-pagination {
  padding-right: 10px;
}

// .ant-table-wrapper {
//   background-color: #f5f5f5 !important;
// }
/***************/

/* Date Picker Classes*/
// .ant-picker {
//   padding: 4px 11px 4px 0px !important;
// }

/***************/

/* Input Field Classes*/
// .ant-input,
// .ant-input[disabled],
// .ant-select:not(.ant-select-customize-input) .ant-select-selector,
// .ant-picker-input > input,
// .ant-picker-input > input[disabled]
// {
//   padding-left: 12px !important;
// }

// .ant-picker-range-separator {
//   padding-right: 0px !important;
// }

// .ant-picker-range .ant-picker-active-bar {
//   width: 80px !important;
// }

.searchFieldContainer .ant-form-item {
  margin-bottom: 0;
}

.labelAlign [class*='ant-form-item-label'] {
  margin-right: 20px;
}

.labelAlign [class*='ant-form-item'] {
  margin-bottom: 0;
}

.custom-kfs-radio-form-item .ant-form-item-label > label.ant-form-item-required.ant-form-item-no-colon::before {
  display: none;
}

// .custom-kfs-radio-form-item .ant-form-item-label > label.ant-form-item-required.ant-form-item-no-colon::after {
//   display: inline-block;
//   color: #ff4d4f;
//   margin-bottom: 2px;
//   margin-left: 4px;
//   font-size: 0.8rem;
//   font-weight: 400;
//   content: '*';
// }

.custom-kfs-text-form-item .ant-form-item-label > label.ant-form-item-required.ant-form-item-no-colon::before {
  content: none !important;
}

// .custom-kfs-text-form-item .ant-form-item-label > label.ant-form-item-required.ant-form-item-no-colon::after {
//   display: inline-block;
//   color: #ff4d4f;
//   margin-bottom: 2px;
//   font-size: 0.8rem;
//   font-weight: 400;
//   content: '*';
// }

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

// .ant-form-item-label
//   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
//   display: inline-block;
//   margin-inline-end: 4px;
//   color: #ff4d4f;
//   font-size: 14px;
//   font-family: SimSun, sans-serif;
//   line-height: 1;
//   content: '*';
// }

/***************/

/* Buttons Classes*/
.kfsFilledBtn,
.kfsFilledBtn:hover,
.kfsFilledBtn:focus {
  background-color: #096dd9 !important;
  color: #ffffff !important;
  border-color: #096dd9 !important;
}

.kfsOutlinedBtn,
.kfsOutlinedBtn:hover,
.kfsOutlinedBtn:focus {
  background-color: #ffffff !important;
  color: #096dd9 !important;
  border-color: #096dd9 !important;
}

.kfsFilledBtn.ant-btn[disabled],
.kfsFilledBtn:hover.ant-btn[disabled],
.kfsFilledBtn:focus.ant-btn[disabled],
.kfsOutlinedBtn[disabled],
.kfsOutlinedBtn:hover[disabled],
.kfsOutlinedBtn:focus[disabled],
.kfsWhiteBlackBtn[disabled],
.kfsWhiteBlackBtn:hover[disabled],
.kfsWhiteBlackBtn:focus[disabled] {
  border-color: #d9d9d9 !important;
  color: #00000040 !important;
  background-color: #f5f5f5 !important;
}

.kfsWhiteBlackBtn,
.kfsWhiteBlackBtn:hover,
.kfsWhiteBlackBtn:focus {
  background-color: #ffffff !important;
  color: #000000d9 !important;
  border-color: #d9d9d9 !important;
}

.newRecordBtn,
.newRecordBtn:focus,
.newRecordBtn:hover {
  height: fit-content !important;
  background-color: #096dd9 !important;
  border: 1px solid #096dd9 !important;
  border-radius: 4px !important;
  margin: 0.16em;
  font-size: 16px !important;
  padding: 6px 14px !important;
}

/***************/

/* Input Field Error Classes*/
.ant-form-item-explain-error {
  white-space: nowrap;
  padding-left: 2px;
}

.hideError .ant-form-item-explain-error {
  display: none;
}
/***************/

/* Breadcrumb*/
.ant-breadcrumb-separator {
  color: black !important;
  font-weight: 500;
}
/***************/

/* Collapse */
.ant-collapse-header-text {
  font-size: 1.2rem;
  color: #00796b !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

//arrow
.customCollapseArrow.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  top: 54%;
  right: auto;
  left: 290px;
  margin: 0;
  transform: translateY(-50%);
  color: #00796b;
}

/***************/

.customHighlight:hover path {
  fill: #2585f4;
  transition: all ease 0.3s;
}

.customHighlight path {
  transition: all ease 0.3s;
}

.customHighlight {
  font-size: 20px;
  vertical-align: middle !important;
  float: right;
}

.ant-tooltip-inner {
  display: inline-block;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* CSS for Menu Header*/
.custom-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

li.ant-menu-submenu-vertical svg.custom-sub-menu-down-arrow {
  display: none;
}

/*Generic Table CSS*/
.editBtn {
  display: inline-flex;
  align-items: center;
}

.filter {
  padding: 8px;
}

@primaryColor: #00689a;
@mainTextColor: #545454;
.filterInput {
  margin-bottom: 8px;
  display: block;
}

.ant-table-thead > tr > th {
  background-color: @primaryColor !important;
}

.filterInputDate {
  margin-top: 5px;
  margin-bottom: 8px;
  display: block;
}

.filterButton {
  width: 90px;
}

.actionBtn {
  display: inline-flex;
  align-items: center;
  margin-right: 28px;
}

li:has(.display-show-total-records) {
  margin-left: 16px;
  margin-right: auto !important;
}

.custom-antd-column-filter-dropdown {
  display: flex;
  flex-direction: column;
}

.formContainer {
  max-width: 600px;
}
.ant-btn-primary {
  background-color: @primaryColor!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: @primaryColor!important;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-btn-primary:not(:disabled):hover.actionBtn {
  background-color: @primaryColor!important;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-btn-default:not(:disabled):hover {
  color: @primaryColor!important;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-btn-primary {
  background-color: @primaryColor !important;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-tag-checkable-checked,
:where(.css-hlymbz).ant-tag-checkable-checked {
  background-color: @primaryColor!important;
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-tag-checkable,
:where(.css-hlymbz).ant-tag-checkable {
  border-color: @primaryColor!important;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-tag {
  white-space: normal !important;
}

.ant-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  color: @primaryColor;
}

/* Default padding for larger screens */
.ant-layout-content {
  padding: 20px;
}

/* Media query for screens with a width of 320px */
@media screen and (max-width: 430px) {
  .ant-layout-content {
    padding: 10px !important;
  }
}

.site-form-item-icon,
:where(.css-dev-only-do-not-override-hlymbz) a,
:where(.css-dev-only-do-not-override-hlymbz).ant-picker-dropdown .ant-picker-today-btn {
  color: @primaryColor!important;
}

.left-icon-button {
  display: flex;
  align-items: center;
}

.left-icon-button .anticon {
  margin-right: 8px; /* Adjust the margin as needed */
}

.main-menu-option {
  height: 59.85714px !important;
  font-size: 21px !important;
}

.ant-btn-default,
.ant-input,
.ant-select-selector,
.ant-picker {
  border-color: @primaryColor !important;
}

.nextSenderBtn {
  float: right;
  width: 48%;
}

.nextSenderBtn2 {
  float: left;
  width: 48%;
}

.prevSenderBtn {
  float: left;
  width: 48%;
  color: @primaryColor !important;
}

.prevSenderBtn2 {
  float: right;
  width: 48%;
  color: #088f42 !important;
}

.right-text {
  text-align: right;
}

:where(.css-dev-only-do-not-override-hlymbz) a {
  font-weight: bold;
}

.full-width {
  width: 100% !important;
}

.normal-font {
  font-weight: normal;
}

.center-text {
  text-align: center;
}

.card-custom {
  width: 100% !important;
  border-color: @primaryColor!important;
}

.card-custom > .ant-card-body {
  padding: 10px !important;
}

.ant-picker {
  border-color: @primaryColor!important;
}

.custom-table {
  border: 1px solid @primaryColor!important; /* Replace with your desired border color */
}

.custom-table thead th,
.ant-input-number,
.ant-input-affix-wrapper {
  border-color: @primaryColor!important; /* Border color for table header cells */
}

.custom-table tbody tr {
  border-bottom: 1px solid @primaryColor!important; /* Border color for table body rows */
}

.custom-table tbody td {
  border-color: @primaryColor!important; /* Border color for table body cells */
}

@media (max-width: 767px) {
  .custom-table {
    overflow-x: auto;
    display: block;
  }
}

:where(.css-dev-only-do-not-override-hlymbz).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-hlymbz).ant-table-wrapper .ant-table-thead > tr > td {
  color: #fff !important;
  background-color: none;
}

.FlexColumnContainer {
  display: flex;
  flex-direction: column;
}

.custom-table tr:hover {
  background-color: @primaryColor!important; /* Change this to your desired hover color */
}

.custom-table tbody tr:hover {
  cursor: pointer;
}

// .ant-space-item:first-child {
//   width: 100%;
// }

@media print {
  .non-printable {
    display: none;
  }

  // @page {
  //   size: landscape;
  // }
}

.custom-input-number .ant-input-number-handler {
  display: none;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: @primaryColor!important;
  background-color: @primaryColor!important;
}

.textColorPrimary {
  color: @primaryColor!important;
}

.tracking-container {
  height: 100vh;
}

.status-item {
  text-align: center;
}

.status-icon {
  font-size: 24px;
  // margin-bottom: 8px;
}

.delivered {
  color: #52c41a;
}

.in-transit {
  color: #1890ff;
}

.failed-delivery {
  color: #ff4d4f;
}

.ant-steps-icon {
  color: #088f42 !important;
  font-size: 34px !important;
}

.ant-steps-item-tail::after {
  background-color: #088f42 !important;
}

.ant-steps-item-container {
  padding-bottom: 10px;
}

:where(.css-hlymbz) a {
  color: @primaryColor !important;
}

.ant-avatar {
  font-size: 13px !important;
}

.webPageHeaderText {
  color: white;
  font-weight: bold;
  text-align: right;
  font-size: 20px;
  padding-right: 20px;
}

.pWebPageHeaderText {
  margin-top: 5px;
  margin-bottom: 5px;
}

.headerMainRow {
  background-color: @primaryColor !important;
}

.webpageheaderClass {
  height: 400%;
}

.custom-step .ant-steps-item-title {
  color: @mainTextColor !important; /* Customize text color */
}

.custom-step .ant-steps-item-icon {
  background-color: @primaryColor !important; /* Customize circle icon color */
}

.custom-step .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important;
  font-size: 18px !important;
}

.custom-step .ant-steps-item-content .ant-steps-item-title {
  font-size: 14px !important; /* Customize content color */
}

.custom-step1 .ant-steps-item-icon {
  background-color: transparent !important; /* Set background color to transparent */
}

.custom-step .ant-steps-item-icon::before {
  border-left: none; /* Remove the vertical underline */
}

.custom-step1 .ant-steps-item-tail {
  display: none !important;
}

.liWhyKFS {
  color: @mainTextColor !important; /* Customize text color */
}

.textColor {
  color: @mainTextColor !important; /* Customize text color */
}

.cardService {
  height: auto !important;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.floatRight {
  float: right;
}

.pleftCom {
  padding-left: 10px;
}

.menu-small {
  font-size: 17px;
  text-align: left;
}

@media screen and (max-width: 320px) {
  .menu-small-320 {
    font-size: 15px;
  }
  .menu-small {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .menu-small-320 {
    font-size: 15px;
  }
  .menu-small {
    font-size: 16px;
  }
}

.homepageIconBookaPickup {
  font-size: 23px;
  margin-bottom: -5px;
  color: @primaryColor;
  margin-right: 5px;
}

.custom-checkbox-tC {
  pointer-events: none;
  color: @primaryColor;
}

.custom-checkbox-tC .ant-checkbox-inner {
  background-color: @primaryColor;
  border-color: @primaryColor;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-checkbox-checked .ant-checkbox-inner {
  background-color: @primaryColor;
  border-color: @primaryColor;
}
.ant-table-column-sorter,
:where(.css-dev-only-do-not-override-hlymbz).ant-table-wrapper .ant-table-filter-trigger.active {
  color: @primaryColor!important;
}

.ant-table-filter-dropdown-btns .ant-btn-link {
  color: @primaryColor;
}
.flex-container-s {
  display: flex;
  flex-direction: column;
}

.serviceCol {
  display: flex !important;
  flex-direction: column !important;
}

.serviceCol > .ant-card {
  flex: 1 !important;
}

.serviceCol .ant-card-body {
  padding: 0%;
}

.serviceImg {
  height: 144px !important;
}

.serviceCol .ant-card-cover {
  margin-inline-start: 0%;
  margin-inline-end: 0%;
}

.serviceCol .ant-card-cover img {
  border-radius: 0%;
}

.balibayantext {
  margin-top: 0px;
  margin-bottom: 7px !important;
}

.boder-balibayantext {
  border: 1px solid @primaryColor;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0px !important;
}

.containerIconCol {
  padding: 0px !important;
  height: 227px;
}

.custom-text-center {
  text-align: center !important;
}

.contact-us-icon {
  font-size: 30px;
  margin-bottom: -5px;
  color: @primaryColor;
}

.contact-us-icon2 {
  font-size: 30px;
  margin-bottom: -5px;
  color: #ffff;
}

.footerColortxt {
  color: #fff !important;
}

.border-sendus {
  border: 1px solid @primaryColor;
  border-radius: 9px;
  padding: 25px;
}

.letsGetStarted {
  // color: @mainTextColor!important;
  text-decoration: underline;
  // font-weight: bold;
}

.mtb {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mtp-0 {
  margin-top: 0px;
}

.mtb-0 {
  margin-bottom: 0px;
}

:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item:hover::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after,
:where(.css-dev-only-do-not-override-hlymbz).ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu:hover::after {
  border-bottom-color: @primaryColor !important;
}

.ant-btn-default-okcancelbooking {
  background-color: #ffff !important;
  color: @mainTextColor!important;
  border-color: @primaryColor;
}

.phoneIcon,
.rewardsIcon {
  padding-left: 10px;
}

.welltake {
  padding-left: 15px !important;
}

.ant-notification-notice-error {
  min-width: 480px !important;
  width: auto !important;
  // max-width: 500px !important;
  top: 350px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
}

.ant-notification-notice-error .ant-notification-notice-message {
  font-size: 14px !important;
}

.ant-notification-notice-error .ant-notification-notice-icon {
  padding-top: 10px;
}

.text-right {
  float: right;
}

.sigCanvas {
  border: 1px solid #088f42;
  border-radius: 9px;
  // width: 100%  ;
}

// .barcodeVertical {
//   display: inline-block;
//   transform: rotate(-90deg);
//   transform-origin: left bottom;
// }

@media print {
  .printing {
    -webkit-transform: rotate(-90deg);
    // -moz-transform: rotate(-90deg);
    // -o-transform: rotate(90deg);
    // -ms-transform: rotate(90deg);
    // filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    // margin-left: -100px !important;
  }

  .margin-pg {
    margin-top: 500px !important;
  }

  .pagebreak {
    clear: both !important;
    page-break-after: always !important;
  }
}

.page1 {
  // -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  margin-left: -100px !important;
}

.pHeaderBarcode {
  margin-bottom: 0px;
  font-size: 141px;
  font-weight: bold;
  margin-left: 268px;
  height: 182px;
}

.textRight1 {
  text-align: right !important;
  font-size: 141px;
  font-weight: bold;
  margin-left: 268px;
}

.textLeft1 {
  text-align: left !important;
  font-size: 141px;
  font-weight: bold;
}

.pHeaderBarcode1 {
  font-size: 200px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 350px;
  height: 260px;
}

.downloadExceltTransaction {
  width: auto !important;
  margin-bottom: 10px;
  color: #fff !important;
}

.bulet-tracking {
  padding-bottom: 0px;
  height: 19px;
}

.font14px {
  font-size: 14px;
}

.tableBorder {
  border: solid 1px @primaryColor;
  border-radius: 12px;
}

.bordercol,
.borderApp {
  border: solid 1px @primaryColor;
  border-radius: 12px;
  padding: 10px;
}

.headermaintext {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.addTextTable {
  float: right;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 600;
  margin-bottom: 5px;
}

.ant-table-cell {
  color: @mainTextColor;
}

@layout-sider-background: white;@layout-header-background: white;@tooltip-color: white;@primary-color: 088f42;