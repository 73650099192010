@primaryColor: #088f42;
.menuAvatar {
  background-color: @primaryColor;
  // color: #f56a00;
  margin-bottom: 5px;
}

.borderBottomUnset {
  border-bottom: unset;
}

.normalFont {
  font-weight: normal;
}

.menuText {
  color: @primaryColor !important;
}

@layout-sider-background: white;@layout-header-background: white;@tooltip-color: white;@primary-color: 088f42;