.RootLayout {
  height: 100vh;
  flex-direction: column;

  .ContentContainer {
    position: relative;
    flex: 1 0 auto;
    background: #fff;
  }
}

@layout-sider-background: white;@layout-header-background: white;@tooltip-color: white;@primary-color: 088f42;